<template>
  <el-space wrap>
    <el-card style="height: 100%; width: 1200px">
      <template #header v-if="active === 0">
        <div class="card-header">
          <AddNewCustomerComponent></AddNewCustomerComponent>
        </div>

        <div class="container-customer">
          <div>
            <PointComponent
              :value="customerExist"
              @input="(newValue) => (customerExist = newValue)"
              title="Controlla se il cliete è già stato registrato"
              label="email"
              placeholder="email"
            ></PointComponent>
          </div>
          <div>
            <ButtonComponent
              label="cerca"
              :isRouter="false"
              @buttonClick="checkCustomerExist"
            ></ButtonComponent>
          </div>
        </div>
      </template>

      <div v-if="active === 0">
        <div class="flex-container">
          <div class="select-component">
            <SelectComponent
              @selected="handleChangeCustomer"
              :options="customersId"
              title="Selezionare un cliente"
              :addButtom="false"
              :highlight="highlightCustomer"
              :isDisabled="false"
              :span="6"
              :value="customerSelected"
            ></SelectComponent>
          </div>

          <div class="select-wrapper select-component">
            <SelectComponent
              @selected="handleChangeCar"
              :options="carsId"
              title="Selezionare un'auto"
              :addButtom="true"
              :highlight="highlightCar"
              :linkPath="`/add-car/${customerSelected}`"
              :isDisabled="!customerSelected"
              :value="carSelected"
              :span="6"
            ></SelectComponent>
          </div>

          <div class="select-component">
            <SelectComponent
              @selected="handleChangeHeadquarter"
              :options="headquartersId"
              title="Selezionare una sede"
              :addButtom="false"
              :highlight="highlightHeadquarter"
              :isDisabled="false"
              :span="6"
              :value="headquarterSelected"
            ></SelectComponent>
          </div>
        </div>

        <el-divider />

        <div class="container">
          <div>Fornire auto di cortesia?</div>
          <div>
            <el-radio-group v-model="booking.loaner" @change="changeLoaner">
              <el-radio label="true">Sì</el-radio>
              <el-radio label="false">No</el-radio>
            </el-radio-group>
          </div>
          <div v-if="booking.loaner == 'true'">
            <el-form-item style="margin-bottom: 1.8%">
              <el-input
                v-model="booking.targa_loaner"
                label="Targa"
                placeholder="Inserisci targa"
              />
            </el-form-item>
          </div>
        </div>
        <el-divider />

        <el-form :model="pickup_gone">
          <div style="margin-top: -2%; text-align: left">
            <el-switch
              class="custom-switch"
              v-model="is_gone"
              active-text="Includi Andata"
              inactive-text="Escludi Andata"
              :active-value="true"
              :inactive-value="false"
            />
          </div>
          <div v-if="is_gone">
            <div class="container-data">
              <DateSelectComponent
                title="Data/ora di arrivo vettura alla sede"
                :loading="loadingSlotsGone"
                :timeSlots="timeSlotsGone"
                :highlightSlot="highlightSlotGone"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateGone"
                @selectedSlot="handleSlotChangeGone"
                @datePartnerChange="handleChangeDateGone"
                :selectedSlotInput="selectedSlotGone"
                :disabledDates="disabledDate"
              ></DateSelectComponent>
              <!-- <DateSelectComponent
                title="Data/ora di arrivo vettura alla sede"
                :loading="loadingSlotsGone"
                :timeSlots="timeSlotsGone"
                :highlightSlot="highlightSlotGone"
                :dateDisabled="!customerSelected || !headquarterSelected"
                :highlight-date="highlightDateGone"
                @selectedSlot="handleSlotChangeGone"
                @datePartnerChange="handleChangeDateGone"
                :selectedSlotInput="selectedSlotGone"
              ></DateSelectComponent> -->
            </div>

            <div class="container-point">
              <PointComponent
                :value="pickup_gone.point.address"
                @input="(newValue) => (pickup_gone.point.address = newValue)"
                title="Punto di prelievo"
                label="Indirizzo"
                placeholder="Indirizzo"
                :span="8"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="pickup_gone.point.street_number"
                @input="
                  (newValue) => (pickup_gone.point.street_number = newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="pickup_gone.point.cap"
                @input="(newValue) => (pickup_gone.point.cap = newValue)"
                label="CAP"
                placeholder="CAP"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="pickup_gone.point.city"
                @input="(newValue) => (pickup_gone.point.city = newValue)"
                :span="4"
                label="Città"
                placeholder="Città"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>

              <PointComponent
                :value="pickup_gone.point.province"
                @input="(newValue) => (pickup_gone.point.province = newValue)"
                label="Provincia"
                placeholder="Provincia"
                @focus="handleInputFocusGone"
                @blur="handleInputBlurGone"
              ></PointComponent>
            </div>

            <DescriptionComponent
              :label="pickup_gone.point.label"
              :loading="loadingPoint"
            ></DescriptionComponent>
          </div>

          <div class="info-container">
            <div class="info-item">
              <PriceComponent
                label="Fascia km"
                :value="`${responseKmGone} km`"
                tagType="info"
              />
            </div>
            <div class="info-item">
              <PriceComponent
                label="Tariffa"
                :value="`${responseCostGone} euro`"
                tagType="success"
              />
            </div>
          </div>
        </el-form>

        <el-divider />

        <el-form :model="pickup_return">
          <div style="margin-top: -2%; text-align: left">
            <el-switch
              class="custom-switch"
              v-model="is_return"
              active-text="Includi Ritorno"
              inactive-text="Escludi Ritorno"
              :active-value="true"
              :inactive-value="false"
            />
          </div>
          <div v-if="is_return">
            <section id="bottom-section">
              <div class="container-data">
                <DateSelectComponent
                  title="Data/ora di ritiro vettura dalla sede"
                  :loading="loadingSlotsReturn"
                  :timeSlots="timeSlotsReturn"
                  :highlightSlot="highlightSlotReturn"
                  :dateDisabled="!customerSelected || !headquarterSelected"
                  :highlight-date="highlightDateReturn"
                  @selectedSlot="handleSlotChangeReturn"
                  @datePartnerChange="handleChangeDateReturn"
                  :selectedSlotInput="selectedSlotReturn"
                  :disabledDates="disabledDateReturn"
                ></DateSelectComponent>
                <!-- <DateSelectComponent
                  title="Data/ora di ritiro vettura dalla sede"
                  :loading="loadingSlotsReturn"
                  :timeSlots="timeSlotsReturn"
                  :highlightSlot="highlightSlotReturn"
                  :dateDisabled="!customerSelected || !headquarterSelected"
                  :highlight-date="highlightDateReturn"
                  @selectedSlot="handleSlotChangeReturn"
                  @datePartnerChange="handleChangeDateReturn"
                  :selectedDate="pickup_return.date_partner"
                  :selectedSlotInput="selectedSlotReturn"
                ></DateSelectComponent> -->
              </div>
            </section>

            <div class="container-point">
              <PointComponent
                :value="pickup_return.point.address"
                @input="(newValue) => (pickup_return.point.address = newValue)"
                title="Punto di consegna"
                label="Indirizzo"
                placeholder="Indirizzo"
                :span="8"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="pickup_return.point.street_number"
                @input="
                  (newValue) => (pickup_return.point.street_number = newValue)
                "
                label="N. civico"
                placeholder="N. civico"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="pickup_return.point.cap"
                @input="(newValue) => (pickup_return.point.cap = newValue)"
                label="CAP"
                placeholder="CAP"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="pickup_return.point.city"
                @input="(newValue) => (pickup_return.point.city = newValue)"
                :span="4"
                label="Città"
                placeholder="Città"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>

              <PointComponent
                :value="pickup_return.point.province"
                @input="(newValue) => (pickup_return.point.province = newValue)"
                label="Provincia"
                placeholder="Provincia"
                @focus="handleInputFocusReturn"
                @blur="handleInputBlurReturn"
              ></PointComponent>
            </div>
            <DescriptionComponent
              :label="pickup_return.point.label"
              :loading="loadingPoint"
            ></DescriptionComponent>

            <div class="info-container">
              <div class="info-item">
                <PriceComponent
                  label="Fascia km"
                  :value="`${responseKmReturn} km`"
                  tagType="info"
                />
              </div>
              <div class="info-item">
                <PriceComponent
                  label="Tariffa"
                  :value="`${responseCostReturn} euro`"
                  tagType="success"
                />
              </div>
            </div>
          </div>

          <el-divider />
          <el-row :gutter="20" style="margin-top: -5%">
            <el-col :span="7">
              <el-form-item label="Pickup residui del cliente">
                <el-input
                  v-model="residual_minuteVal"
                  label="pickup rimasti"
                  readonly="readonly"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <br />
        <el-row justify="center" style="margin-top: -1%">
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/"
            @buttonClick="annulla"
          ></ButtonComponent>
          <ButtonComponent
            label="Avanti"
            :isRouter="false"
            @buttonClick="next"
          ></ButtonComponent>
        </el-row>
      </div>
      <div v-if="active === 1">
        <el-form :model="booking">
          <el-scrollbar height="280px">
            <div style="padding-bottom: 2%; padding-top: 2%">
              Aggiungi dei lavori di manutenzione alla vettura:
            </div>
            <div
              v-for="(task, counter_task) in booking.tasks"
              v-bind:key="counter_task"
              width="100%"
            >
              <div class="task-row">
                <AggiungiLavori
                  :InputDescription="task.description"
                  :InputCost="task.cost"
                  :InputPay="task.pay"
                  @description="(newValue) => (task.description = newValue)"
                  @cost="(newValue) => (task.cost = newValue)"
                  @pay="(newValue) => (task.pay = newValue)"
                  :highlightDescription="highlightDescription"
                  :highlightCost="highlightCost"
                ></AggiungiLavori>

                <div style="margin-bottom: 2%">
                  <ButtonComponent
                    :ifCondition="counter_task != 0 ? true : false"
                    :isRouter="false"
                    @buttonClick="rimuoviTask($event, counter_task)"
                    type="danger"
                    icon="Delete"
                  ></ButtonComponent>
                </div>
              </div>
            </div>
            <ButtonComponent
              label="Aggiungi lavori"
              :isRouter="false"
              @buttonClick="aggiungiTask"
              type="success"
            ></ButtonComponent>
          </el-scrollbar>

          <br />
          <br />
          <br />
          <el-divider />

          <div>
            <UploadFile
              label="Carica preventivo"
              @note="handleChangeNote"
              @uploadChange="onFileSelected"
              style="margin-top: 1%"
            ></UploadFile>
          </div>
          <el-row justify="center" style="margin-top: 3.5%">
            <ButtonComponent
              label="Indietro"
              :isRouter="false"
              @buttonClick="back"
            ></ButtonComponent>
            <ButtonComponent
              label="Crea"
              :isRouter="false"
              @buttonClick="onSubmit"
            ></ButtonComponent>
          </el-row>
        </el-form>
      </div>
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="Step 1" />
        <el-step title="Step 2" />
      </el-steps>
    </el-card>
  </el-space>
</template>

<script>
import prenotazioneAPI from '../services/prenotazioneAPI.js';
import carAPI from '../services/carAPI.js';
import employeeAPI from '../services/employeeAPI.js';
import customerAPI from '../services/customerAPI';
import store from '../store/prenotazione.js';
import bookingAPI from '@/services/bookingAPI';
import '@vuepic/vue-datepicker/dist/main.css';
import { isLoggedStore } from '../store/logged';
import { useRouter } from 'vue-router';
import { reactive, ref, toRaw } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import { isEmpty } from 'lodash';
import AddNewCustomerComponent from './AddNewCustomerComponent.vue';
import DateSelectComponent from './DateSelectComponent.vue';
import SelectComponent from './SelectComponent.vue';
import PointComponent from './PointComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import DescriptionComponent from './DescriptionComponent.vue';
import PriceComponent from './PriceComponent.vue';
import AggiungiLavori from './AggiungiLavori.vue';
import UploadFile from './UploadFile';

import partnerAPI from '../services/partnerAPI.js';

const dayjs = require('dayjs');

export default {
  name: 'PickupComponent',
  components: {
    AddNewCustomerComponent,
    DateSelectComponent,
    PointComponent,
    SelectComponent,
    ButtonComponent,
    DescriptionComponent,
    PriceComponent,
    AggiungiLavori,
    UploadFile,
  },

  setup() {
    const router = useRouter();
    const storeLogin = isLoggedStore();

    //pulsante "avanti" disabilitato prima di aver inserito tutte le informazioni
    let button1_disabled = ref(true);

    let loadingSlotsGone = ref(true);
    let loadingSlotsReturn = ref(true);

    const tasks = [
      {
        cost: 0,
        description: '',
        pay: false,
      },
    ];

    let formRulePickup = reactive({
      date_partner: [
        {
          required: true,
          message: 'Inserire date_partner',
          trigger: 'blur',
        },
      ],
    });

    let formRuleBooking = reactive({
      customer: [
        {
          required: true,
          message: 'Inserire customer',
          trigger: 'blur',
        },
      ],
      headquarter: [
        {
          required: true,
          message: 'Inserire headquarter',
          trigger: 'blur',
        },
      ],
    });

    const booking = reactive({
      payed: false,
      imponibile: 0,
      tot_fattura: 0,
      rif_fattura: 0,
      liquidato: false,
      data: new Date(),
      over_twentysix: false,
      loaner: 'false',
      targa_loaner: '',
      state: 'before',
      type: 'both',
      note: '',
      customer: store.customerSelectedObj,
      car: {},
      employee: storeLogin.employee,
      headquarter: store.heaquarterSelectedObj,
      tasks: [
        {
          description: '',
          cost: 0,
          pay: false,
          accepted: false,
        },
      ],
      type_prenotazione: 'pickup',

      pickup_bookings: [],
    });

    const pickup_gone = reactive({
      date_partner: '',
      new_date_partner: null,
      date_customer: '',
      date_driver: '',
      date_jolly_scan: '',
      true_date_partner: '',
      true_date_customer: '',
      true_date_driver: '',
      simba_km: 0,
      simba_km_range: 0,
      simba_imponibile: 0.0,
      simba_minute: 0,
      type_booking: 'gone',
      order: 0,
      qrCode: '',
      driver_pickup: null,
      planner: null,
      point: {
        address: store.originPointAddress, //"",
        street_number: store.originPointStreetNumber, //",
        cap: store.originPointCity, //"",
        city: store.originPointCap, //"",
        province: store.originPointProvince, //"",
        escort_bookings: null,
        delivery_bookings: null,
        pickup_bookings: null,
        label: '',
        latitude: 0,
        longitude: 0,
      },
      planners: [],
    });

    const pickup_return = reactive({
      date_partner: '',
      date_customer: '',
      date_driver: '',
      date_jolly_scan: '',
      true_date_partner: '',
      true_date_customer: '',
      true_date_driver: '',
      simba_km: 0,
      simba_km_range: 0,
      simba_imponibile: 0.0,
      simba_minute: 0,
      type_booking: 'return',
      order: 0,
      qrCode: '',
      driver_pickup: null,
      planner: null,
      point: {
        address: store.destinationPointAddress,
        street_number: store.destinationPointStreetNumber,
        cap: store.destinationPointCity,
        city: store.destinationPointCap,
        province: store.destinationPointProvince,
        escort_bookings: null,
        delivery_bookings: null,
        pickup_bookings: null,
        label: '',
        latitude: 0,
        longitude: 0,
      },
      planners: [],
    });

    let customerExist = ref('');

    const checkCustomerExist = async () => {
      try {
        let request = {
          mail: customerExist.value,
        };

        const response = await customerAPI.checkCustomerExist(request);

        if (response.data.exist == true) {
          ElMessageBox.confirm(
            `Nome: ${response.data.customer.name}<br>Cognome: ${response.data.customer.surname}<br>Email: ${response.data.customer.mail}`,
            'Cliente Trovato',
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: 'Aggiungi il cliente',
              cancelButtonText: 'Annulla',
            }
          )
            .then(async () => {
              await addPartnerToCustomer();
            })
            .catch(() => {});
        } else {
          ElMessageBox.confirm(
            'Il cliente non esiste, registralo prima di prenotare',
            {
              confirmButtonText: 'Registra un cliente',
              cancelButtonText: 'Cancel',
              type: 'warning',
              customClass: 'custom-message-box',
            }
          )
            .then(() => {
              router.push('/addcustomer_employee');
            })
            .catch(() => {});
        }
      } catch (error) {
        ElMessage({
          message: 'Si è verificato un errore durante la verifica del cliente',
          type: 'error',
        });
        console.log(
          error,
          'Si è verificato un errore durante la verifica del cliente'
        );
      }
    };
    //cliente
    let customerSelected = ref(store.customerSelected);
    const customersId = store.customersCascader;
    let customers = store.customers;

    const addPartnerToCustomer = async () => {
      const responsePartner = await partnerAPI.getPartnerByEmployee(
        storeLogin.id
      );

      const partner = responsePartner.data[0].headquarters[0].partner;

      try {
        let request = {
          mail: customerExist.value,
          partner: partner,
        };

        const response = await customerAPI.addPartnerToCustomer(request);

        customerSelected.value = response.data.customer.id;

        booking.customer = response.data.customer;

        store.customerSelectedObj = response.data.customer;

        const customerExistsInStore = customersId.some(
          (customer) => customer.value === response.data.customer.id
        );

        if (!customerExistsInStore) {
          store.customers.push(response.data.customer);
        }

        //store.customers.push(response.data.customer);

        let tableRow = {
          value: booking.customer.id,
          label: `${booking.customer.name} ${booking.customer.surname}, ${booking.customer.mail}`,
        };

        if (!customerExistsInStore) {
          store.customersCascader.push(tableRow);
        }

        //await loadCars(booking.customer.id);

        //customers= [response.data.customer]

        //await loadCustomers();
      } catch (error) {
        console.log(error);
      }
    };

    /* const loadCustomers = async () => {
      try {
        const responsePartner = await partnerAPI.getPartnerByEmployee(storeLogin.id);
        const response = await customerAPI.getCustomersByPartner(responsePartner.data[0].headquarters[0].partner.id);

        response.data.forEach((customer) => {
          if (customer.is_alive == true) {
            let tableRow = {
              value: customer.id,
              label: `${customer.name} ${customer.surname}, ${customer.mail}`,
            };
            store.customers.push(customer);
            store.customersCascader.push(tableRow);
          }
        });
      } catch (error) {
        console.log(error);
        console.log("error customer");
      }
    }; */

    const handleChangeCustomer = async (val) => {
      /*       if(val){
        customerSelected.value = val;
        booking.customer = customers.filter((customer) => customer.id == val)[0];
      }else{

      } */
      customerSelected.value = val ? val : booking.customer.id;
      carsId.value = [];

      booking.customer = customers.filter((customer) => customer.id == val)[0];

      store.customerSelected = booking.customer.id;
      store.customerSelectedObj = booking.customer;

      pickup_gone.point.address = booking.customer.address;
      pickup_gone.point.street_number = booking.customer.street_number;
      pickup_gone.point.cap = booking.customer.cap;
      pickup_gone.point.city = booking.customer.city;
      pickup_gone.point.province = booking.customer.province;

      store.originPointAddress = pickup_gone.point.address;
      store.originPointStreetNumber = pickup_gone.point.street_number;
      store.originPointCity = pickup_gone.point.cap;
      store.originPointCap = pickup_gone.point.city;
      store.originPointProvince = pickup_gone.point.province;

      pickup_return.point.address = booking.customer.address;
      pickup_return.point.street_number = booking.customer.street_number;
      pickup_return.point.cap = booking.customer.cap;
      pickup_return.point.city = booking.customer.city;
      pickup_return.point.province = booking.customer.province;

      let resposnePointGone = await loadPoints(pickup_gone.point);

      pickup_gone.point.label = resposnePointGone.label;
      pickup_gone.point.latitude = resposnePointGone.point.latitude;
      pickup_gone.point.longitude = resposnePointGone.point.longitude;

      let resposnePoinReturn = await loadPoints(pickup_return.point);

      pickup_return.point.label = resposnePoinReturn.label;
      pickup_return.point.latitude = resposnePoinReturn.point.latitude;
      pickup_return.point.longitude = resposnePoinReturn.point.longitude;

      store.destinationPointAddress = pickup_return.point.address;
      store.destinationPointStreetNumber = pickup_return.point.street_number;
      store.destinationPointCity = pickup_return.point.cap;
      store.destinationPointCap = pickup_return.point.city;
      store.destinationPointProvince = pickup_return.point.province;

      if (typeof val === 'object' && !Array.isArray(val) && val !== null) {
        await loadCars(val[0]);
      } else {
        await loadCars(val);
      }

      isAvantiButtonDisabled();
      calcolaKmGone();
      calcolaKmReturn();
    };
    //macchina
    let carSelected = ref(store.carSelected);
    /*  let carsId = ref(store.carsCascader);
        const cars = store.cars;*/
    let carsId = ref([]);
    const cars = ref([]);

    const loadCars = async (customer_id) => {
      try {
        //const partner = await partnerAPI.getPartnerByEmployee(booking.employee.id);
        //console.log("partner", partner.data[0].headquarters[0].partner.id)

        const response = await carAPI.getCarsByCustomer(
          customer_id /* , partner.data[0].headquarters[0].partner.id */
        );
        let tableRow = {};

        response.data.forEach((car) => {
          if (car.minutes.length != 0) {
            car.minutes.forEach((minute) => {
              if (minute.accepted == true) {
                tableRow = {
                  value: car.id,
                  label: `${car.marca} ${car.modello} ${car.targa}`,
                };
                carsId.value.push(tableRow);
                cars.value.push(car);
              }
            });
          } else {
            tableRow = {
              value: car.id,
              label: `${car.marca} ${car.modello} ${car.targa}`,
            };
            carsId.value.push(tableRow);
            cars.value.push(car);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };

    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      ///e.log("🚀 ~ loadPoints ~ point:", point)
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });

        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    const handleChangeCar = async (val) => {
      booking.car = cars.value.filter((car) => car.id == val)[0];
      carSelected.value = booking.car.id;
      isAvantiButtonDisabled();
      loadMinutes(booking.headquarter.id);
    };

    //sede
    let headquarterSelected = ref(store.heaquarterSelected);
    let headquartersId = store.headquartersCascader;

    const headquarters = store.headquarters;

    const handleChangeHeadquarter = async (val) => {
      headquarterSelected.value = val;
      booking.headquarter = headquarters.filter(
        (headquarter) => headquarter.id == val
      )[0];

      store.heaquarterSelected = booking.headquarter.id;
      store.heaquarterSelectedObj = booking.headquarter;

      calcolaKmGone();
      calcolaKmReturn();

      isAvantiButtonDisabled();
      loadMinutes(toRaw(val[0]));
    };

    let show_minute = true;
    let residual_minuteVal = ref(0);
    let minuteCar = ref({});

    const loadMinutes = async (headquartersId) => {
      try {
        if (headquartersId && booking.car.id) {
          const partner_response = await employeeAPI.getPartnerByHeadquarter(
            headquartersId
          );
          const response = await customerAPI.getMinuteByCar(
            booking.car.id,
            partner_response.data[0].partner.id
          );
          //const today = new Date();

          let expirydate = new Date();
          const expiry = dayjs(expirydate).format();

          let expireDate = dayjs(response.data[0].expiration_valet)
            .utc()
            .format();

          if (response.data.length == 0 || expireDate < expiry) {
            show_minute = false;
            residual_minuteVal.value = 0;
          } else {
            minuteCar.value = response.data[0];

            residual_minuteVal.value = response.data[0].residual_valet;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    //scelta di solo andata, solo ritorno o entrambi
    let is_gone = ref(true);
    let is_return = ref(true);

    const isAvantiButtonDisabled = () => {
      const pickupGone = pickup_gone.point;

      const pickupReturn = pickup_return.point;

      if (is_return.value && is_gone.value) {
        if (
          (pickupGone.address == '' &&
            pickupGone.street_number == '' &&
            pickupGone.city == '' &&
            pickupGone.province == '' &&
            pickupGone.cap == '') ||
          (pickupReturn.address == '' &&
            pickupReturn.street_number == '' &&
            pickupReturn.city !== '' &&
            pickupReturn.province == '' &&
            pickupReturn.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.car)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          pickup_gone.date_partner == '' ||
          pickup_return.date_partner == ''
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      } else if (!is_return.value && is_gone.value) {
        if (
          (pickupGone.address == '' &&
            pickupGone.street_number == '' &&
            pickupGone.city == '' &&
            pickupGone.province == '' &&
            pickupGone.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.car)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          pickup_gone.date_partner == ''
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      } else if (is_return.value && !is_gone.value) {
        if (
          (pickupReturn.address == '' &&
            pickupReturn.street_number == '' &&
            pickupReturn.city == '' &&
            pickupReturn.province == '' &&
            pickupReturn.cap == '') ||
          isEmpty(toRaw(booking.customer)) ||
          isEmpty(toRaw(booking.car)) ||
          isEmpty(toRaw(booking.headquarter)) ||
          pickup_return.date_partner == ''
        ) {
          button1_disabled.value = true;
        } else {
          button1_disabled.value = false;
        }
      }
      return true;
    };

    const deleteGone = () => {
      is_gone.value = !is_gone.value;
      isAvantiButtonDisabled();
    };

    const deleteReturn = () => {
      is_return.value = !is_return.value;
      isAvantiButtonDisabled();
    };

    //ottengo slot disponibili
    const selectedSlotGone = ref(null);
    const selectedSlotReturn = ref(null);
    const timeSlotsGone = ref([
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);
    const timeSlotsReturn = ref([
      {
        value: '08:45',
        label: '08:45',
      },
      {
        value: '09:00',
        label: '09:00',
      },
      {
        value: '09:15',
        label: '09:15',
      },
      {
        value: '09:30',
        label: '09:30',
      },
      {
        value: '09:45',
        label: '09:45',
      },
      {
        value: '10:00',
        label: '10:00',
      },
      {
        value: '10:15',
        label: '10:15',
      },
      {
        value: '10:30',
        label: '10:30',
      },

      {
        value: '10:45',
        label: '10:45',
      },
      {
        value: '11:00',
        label: '11:00',
      },
      {
        value: '11:15',
        label: '11:15',
      },
      {
        value: '11:30',
        label: '11:30',
      },

      {
        value: '11:45',
        label: '11:45',
      },
      {
        value: '12:00',
        label: '12:00',
      },
      {
        value: '12:15',
        label: '12:15',
      },
      {
        value: '12:30',
        label: '12:30',
      },
      {
        value: '12:45',
        label: '12:45',
      },
      {
        value: '13:00',
        label: '13:00',
      },
      {
        value: '13:15',
        label: '13:15',
      },
      {
        value: '13:30',
        label: '13:30',
      },
      {
        value: '13:45',
        label: '13:45',
      },
      {
        value: '14:00',
        label: '14:00',
      },
      {
        value: '14:15',
        label: '14:15',
      },
      {
        value: '14:30',
        label: '14:30',
      },
      {
        value: '14:45',
        label: '14:45',
      },
      {
        value: '15:00',
        label: '15:00',
      },
      {
        value: '15:15',
        label: '15:15',
      },
      {
        value: '15:30',
        label: '15:30',
      },
      {
        value: '15:45',
        label: '15:45',
      },
      {
        value: '16:00',
        label: '16:00',
      },
      {
        value: '16:15',
        label: '16:15',
      },
      {
        value: '16:30',
        label: '16:30',
      },
      {
        value: '16:45',
        label: '16:45',
      },
      {
        value: '17:00',
        label: '17:00',
      },
      {
        value: '17:15',
        label: '17:15',
      },
      {
        value: '17:30',
        label: '17:30',
      },
      {
        value: '17:45',
        label: '17:45',
      },
      {
        value: '18:00',
        label: '18:00',
      },
      {
        value: '18:15',
        label: '18:15',
      },
      {
        value: '18:30',
        label: '18:30',
      },
      {
        value: '18:45',
        label: '18:45',
      },
    ]);

    const handleChangeDateGone = async (val) => {
      try {
        pickup_gone.date_partner = val;
        //const selectedDate = dayjs(pickup_gone.date_partner).format("YYYY-MM-DD");
        await loadSlotGone(
          dayjs(pickup_gone.date_partner).format('YYYY-MM-DD')
        );
      } catch (error) {
        console.error(error);
      }
    };

    const handleChangeDateReturn = async (val) => {
      try {
        pickup_return.date_partner = val;
        const selectedDate = dayjs(pickup_return.date_partner).format(
          'YYYY-MM-DD'
        );
        await loadSlotReturn(selectedDate);
      } catch (error) {
        console.error(error);
      }
    };

    let request_slot_gone = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotGone = async (date) => {
      loadingSlotsGone.value = true;
      let newSlot = '';
      handleSlotChangeGone(newSlot);
      try {
        if (
          !isEmpty(toRaw(booking.headquarter)) &&
          !isEmpty(toRaw(pickup_gone.point)) &&
          date != ''
          //pickup_gone.date_partner != ""
        ) {
          if (booking.loaner == 'false') {
            request_slot_gone.originPoint = toRaw(booking.headquarter);
            request_slot_gone.destinationPoint = toRaw(pickup_gone.point);
            request_slot_gone.partnerDay = date;
            request_slot_gone.type = 'PICKUP_GONE';
          } else {
            request_slot_gone.originPoint = toRaw(booking.headquarter);
            request_slot_gone.destinationPoint = toRaw(pickup_gone.point);
            request_slot_gone.partnerDay = date;
            request_slot_gone.type = 'PICKUP_AUTO_GONE';
          }

          let response = null;
          request_slot_gone.area = booking.headquarter.area;

          response = await prenotazioneAPI.calcSlot(request_slot_gone);

          if (response) {
            loadingSlotsGone.value = false;
          }
          return response.data;
        }
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    let request_slot_return = {
      originPoint: {},
      destinationPoint: {},
      partnerDay: new Date(),
      type: '',
      area: '',
    };

    const SlotReturn = async (date) => {
      loadingSlotsReturn.value = true;
      let newSlot = '';
      handleSlotChangeReturn(newSlot);
      try {
        if (
          !isEmpty(toRaw(booking.headquarter)) &&
          !isEmpty(toRaw(pickup_return.point))
        ) {
          if (booking.loaner == 'false') {
            request_slot_return.originPoint = toRaw(booking.headquarter);
            request_slot_return.destinationPoint = toRaw(pickup_return.point);
            request_slot_return.type = 'PICKUP_RETURN';
            request_slot_return.partnerDay = date;
          } else {
            request_slot_return.originPoint = toRaw(booking.headquarter);
            request_slot_return.destinationPoint = toRaw(pickup_return.point);
            request_slot_return.type = 'PICKUP_AUTO_RETURN';
            request_slot_return.partnerDay = date;
          }
          request_slot_return.area = booking.headquarter.area;
          let response_slot = await prenotazioneAPI.calcSlot(
            request_slot_return
          );
          loadingSlotsReturn.value = false;
          return response_slot.data;
        }
      } catch (error) {
        console.error('Error loading slots:', error);
        return null;
      }
    };

    const mergeSlotsGone = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotGone(selectedDate);
      //const availableSlots = ['09:00'];
      loadingSlotsGone.value = false;
      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          const isAvailable = availableSlots.includes(timeSlot.value);

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const mergeSlotsReturn = async (timeSlots, selectedDate) => {
      // 1: carico slot disponibili dal backend
      const availableSlots = await SlotReturn(selectedDate);
      //const availableSlots = ['13:00'];
      loadingSlotsReturn.value = false;
      if (availableSlots) {
        // 2: seleziono gli slot disponibili
        const mergedSlots = timeSlots.map((timeSlot) => {
          let isAvailable = availableSlots.includes(timeSlot.value);

          const [hour, minute] = timeSlot.value.split(':').map(Number);

          const newDate = dayjs(selectedDate)
            .hour(hour)
            .minute(minute)
            .second(0)
            .toDate();

          isAvailable =
            isAvailable && is_gone.value
              ? new Date(newDate).getTime() >
                new Date(pickup_gone.date_partner).getTime()
              : isAvailable;

          return {
            ...timeSlot,
            disabled: !isAvailable,
          };
        });
        return mergedSlots;
      }
      return [];
    };

    const loadSlotGone = async (selectedDate) => {
      if (selectedDate != '') {
        const mergedSlotsGone = await mergeSlotsGone(
          timeSlotsGone.value,
          selectedDate
        );

        timeSlotsGone.value.splice(
          0,
          timeSlotsGone.value.length,
          ...mergedSlotsGone
        );
      }
    };

    const loadSlotReturn = async (selectedDate) => {
      if (selectedDate != '') {
        const mergedSlotsReturn = await mergeSlotsReturn(
          timeSlotsReturn.value,
          selectedDate
        );
        timeSlotsReturn.value.splice(
          0,
          timeSlotsReturn.value.length,
          ...mergedSlotsReturn
        );
      }
    };

    const handleSlotChangeGone = (value) => {
      selectedSlotGone.value = value;
      if (value != '') {
        pickup_gone.date_partner = dayjs(new Date(pickup_gone.date_partner))
          .hour(value[0].split(':')[0])
          .minute(value[0].split(':')[1])
          .second(0)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }

      isAvantiButtonDisabled();
    };

    const handleSlotChangeReturn = (value) => {
      selectedSlotReturn.value = value;

      if (value != '') {
        pickup_return.date_partner = dayjs(new Date(pickup_return.date_partner))
          .hour(value[0].split(':')[0])
          .minute(value[0].split(':')[1])
          .second(0)
          .format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }

      isAvantiButtonDisabled();
    };

    //scelta dell'auto di cortesia da fornire al cliente

    const changeLoaner = (val) => {
      booking.loaner = val;

      if (is_gone.value && is_return.value) {
        calcolaKmGone();
        calcolaKmReturn();
        selectedSlotGone.value = null;
        selectedSlotReturn.value = null;
        if (pickup_gone.date_partner != '')
          loadSlotGone(pickup_gone.date_partner);
        if (pickup_return.date_partner != '')
          loadSlotReturn(pickup_return.date_partner);
      } else if (is_gone.value && !is_return.value) {
        calcolaKmGone();
        selectedSlotGone.value = null;
        if (pickup_gone.date_partner != '')
          loadSlotGone(pickup_gone.date_partner);
      } else if (!is_gone.value && is_return.value) {
        calcolaKmReturn();
        selectedSlotReturn.value = null;
        if (pickup_return.date_partner != '')
          loadSlotReturn(pickup_return.date_partner);
      }
    };

    let button2_disabled = ref(true);

    //gestione step della prenotazione
    let active = ref(0);

    let highlightCustomer = ref(false);
    let highlightHeadquarter = ref(false);
    let highlightCar = ref(false);

    let highlightDateGone = ref(false);
    let highlightDateReturn = ref(false);
    let highlightSlotGone = ref(false);
    let highlightSlotReturn = ref(false);

    const next = () => {
      if (!customerSelected.value) {
        ElMessage.error('Perfavore inserisci un cliente');
        highlightCustomer.value = true;
        //highlightHeadquarter.value = true;
        return;
      }

      if (!Object.keys(booking.car).length) {
        ElMessage.error('Perfavore inserisci auto');
        highlightCar.value = true;
        return;
      }

      if (!headquarterSelected.value) {
        ElMessage.error('Perfavore inserisci una sede');
        highlightHeadquarter.value = true;
        return;
      }

      if (is_gone.value && pickup_gone.date_partner == '') {
        ElMessage.error('Perfavore inserisci una data di ritiro auto');
        highlightDateGone.value = true;
        return;
      }

      if (is_gone.value && !selectedSlotGone.value) {
        ElMessage.error('Perfavore inserisci un orario');
        highlightSlotGone.value = true;
        return;
      }

      if (is_return.value && pickup_return.date_partner == '') {
        ElMessage.error('Perfavore inserisci una data di consegna auto');
        highlightDateReturn.value = true;
        return;
      }

      if (is_return.value && !selectedSlotReturn.value) {
        ElMessage.error('Perfavore inserisci un orario');
        highlightSlotReturn.value = true;
        return;
      }

      highlightCustomer.value = false;
      highlightHeadquarter.value = false;
      highlightDateGone.value = false;
      highlightDateReturn.value = false;
      highlightSlotGone.value = false;
      highlightSlotReturn.value = false;

      if (active.value++ > 1) {
        active.value = 0;
      }
    };

    //upload file
    let selectedFile1 = null;
    let fd = new FormData();
    const onFileSelected = (e) => {
      for (let file of e.target.files) {
        selectedFile1 = file;
        fd.append('files', selectedFile1, selectedFile1.name);
      }
      return fd;
    };

    const fileLoad = async (fd) => {
      try {
        const mail = booking.customer.mail;
        await prenotazioneAPI.upload(fd, mail).then((resp) => {
          if (resp.status == 201) {
            console.log('riuscito');
          }
        });
      } catch (e) {
        console.log(e);
      }
    };

    //range del booking
    let responseKmGone = ref(store.rangeKmGonePickup);
    let responseKmReturn = ref(store.rangeKmReturnPickup);
    let responseCostGone = ref(store.rangeCostGonePickup);
    let responseCostReturn = ref(store.rangeCostReturnPickup);

    let request = {
      headquarter_id: '',
      origins_point: {},
      destination_point: {},
      loaner: false,
    };

    const calcolaKmGone = async () => {
      try {
        request.headquarter_id = booking.headquarter.id;
        request.origins_point = toRaw(pickup_gone.point);
        request.destination_point = toRaw(booking.headquarter);

        request.loaner = booking.loaner.toLowerCase() === 'true';

        if (
          !isEmpty(toRaw(booking.headquarter)) //&&
          //!isEmpty(toRaw(booking.customer))
        ) {
          const response = await bookingAPI.getRangePickup(request);
          console.log("🚀 ~ calcolaKmGone ~ request:", request)
          console.log("🚀 ~ calcolaKmGone ~ response:", response)

          pickup_gone.simba_minute = response.data.minute;

          pickup_gone.simba_km = response.data.km;
          pickup_gone.simba_imponibile = response.data.imponibile;
          pickup_gone.simba_km_range = response.data.kmRange;

          responseKmGone.value = response.data.kmRange;
          responseCostGone.value = response.data.cost;

          store.rangeKmGonePickup = response.data.kmRange;
          store.rangeCostGonePickup = response.data.cost;
        }
      } catch (error) {
        console.log(error);
      }
    };

    calcolaKmGone();

    const calcolaKmReturn = async () => {
      request.headquarter_id = booking.headquarter.id;
      request.origins_point = toRaw(booking.headquarter);
      request.destination_point = toRaw(pickup_return.point);
      request.loaner = booking.loaner.toLowerCase() === 'true';

      if (
        !isEmpty(toRaw(booking.headquarter)) //&&
        //!isEmpty(toRaw(booking.customer))
      ) {
        const response = await bookingAPI.getRangePickup(request);

        pickup_return.simba_minute = response.data.minute;

        pickup_return.simba_km = response.data.km;
        pickup_return.simba_imponibile = response.data.imponibile;
        pickup_return.simba_km_range = response.data.kmRange;

        responseKmReturn.value = response.data.kmRange;
        responseCostReturn.value = response.data.cost;

        store.destinationPointAddress = request.destination_point.address;
        store.destinationPointStreetNumber =
          request.destination_point.street_number;
        store.destinationPointCity = request.destination_point.city;
        store.destinationPointCap = request.destination_point.cap;
        store.destinationPointProvince = request.destination_point.province;
      }
    };

    calcolaKmReturn();

    isAvantiButtonDisabled();

    const rimuoviTask = (e, counter) => {
      booking.tasks.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiTask = (e) => {
      booking.tasks.push({
        description: '',
        cost: 0,
        pay: false,
      });
      e.preventDefault();
    };

    const addBookingNew = async (booking) => {
      let response = {};
      let headquarter ={
        address:booking.headquarter.address,
        street_number:booking.headquarter.street_number,
        cap:booking.headquarter.cap,
        city:booking.headquarter.city,
        province:booking.headquarter.province,
        latitude:booking.headquarter.latitude,
        longitude:booking.headquarter.longitude,
}
      
      try {
        booking.loaner = booking.loaner.toLowerCase() === 'true';
        if (booking.loaner) {
          booking.type_prenotazione = 'pickup_loaner';
        }

        let newPlannerGone = null;
        let newPlannerReturn = null;

        if (is_gone.value && is_return.value) {
          booking.type = 'both';
          booking.data = pickup_gone.date_partner;
          booking.imponibile =
            pickup_gone.simba_imponibile + pickup_return.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          if (booking.loaner) {
            newPlannerGone = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_gone.point),
              pickup_gone.date_partner,
              'PICKUP_AUTO_GONE'
            );
            pickup_gone.planners.push(newPlannerGone.data.plannerDriver);

            //pickup_return.date_partner = dayjs(pickup_return.date_partner).format("YYYY-MM-DD HH:mm:ss")

            newPlannerReturn = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_return.point),
              pickup_return.date_partner,
              'PICKUP_AUTO_RETURN'
            );
            pickup_return.planners.push(newPlannerReturn.data.plannerDriver);

            pickup_gone.date_customer =
              newPlannerGone.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;
            pickup_gone.date_driver =
              newPlannerGone.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;

            pickup_return.date_customer =
              newPlannerReturn.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;
            pickup_return.date_driver =
              newPlannerReturn.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;

            pickup_gone.driver_pickup = newPlannerGone.data.driver.driverId;
            pickup_return.driver_pickup = newPlannerReturn.data.driver.driverId;
          } else {
            newPlannerGone = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_gone.point),
              pickup_gone.date_partner,
              'PICKUP_GONE'
            );
            pickup_gone.planners.push(newPlannerGone.data.plannerDriver);
            pickup_gone.planners.push(newPlannerGone.data.plannerJolly);

            //console.log("🚀 ~ addBookingNew ~ *********************:", pickup_gone.date_customer)

            newPlannerReturn = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_return.point),
              pickup_return.date_partner,
              'PICKUP_RETURN'
            );

            pickup_return.planners.push(newPlannerReturn.data.plannerDriver);
            pickup_return.planners.push(newPlannerReturn.data.plannerJolly);

            pickup_gone.date_customer =
              newPlannerGone.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;
            pickup_gone.date_driver =
              newPlannerGone.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;

            pickup_return.date_customer =
              newPlannerReturn.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;
            pickup_return.date_driver =
              newPlannerReturn.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;

            pickup_gone.driver_pickup = newPlannerGone.data.driver.driverId;
            pickup_return.driver_pickup = newPlannerReturn.data.driver.driverId;
          }

          booking.pickup_bookings.push(pickup_gone);
          booking.pickup_bookings.push(pickup_return);
        } else if (is_gone.value && !is_return.value) {
          booking.type = 'gone';
          booking.data = pickup_gone.date_partner;
          booking.imponibile = pickup_gone.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          if (booking.loaner) {
            newPlannerGone = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_gone.point),
              pickup_gone.date_partner,
              'PICKUP_AUTO_GONE'
            );
            pickup_gone.planners.push(newPlannerGone.data.plannerDriver);

            pickup_gone.date_customer =
              newPlannerGone.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;
            pickup_gone.date_driver =
              newPlannerGone.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;

            pickup_gone.driver_pickup = newPlannerGone.data.driver.driverId;
          } else {
            newPlannerGone = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_gone.point),
              pickup_gone.date_partner,
              'PICKUP_GONE'
            );
            pickup_gone.planners.push(newPlannerGone.data.plannerDriver);
            pickup_gone.planners.push(newPlannerGone.data.plannerJolly);

            pickup_gone.date_customer =
              newPlannerGone.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;
            pickup_gone.date_driver =
              newPlannerGone.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerGone.data.driver.trips[0].dateStart
                : newPlannerGone.data.driver.trips[1].dateStart;

            pickup_gone.driver_pickup = newPlannerGone.data.driver.driverId;
          }

          booking.pickup_bookings.push(pickup_gone);
        } else if (!is_gone.value && is_return.value) {
          booking.type = 'return';
          booking.data = pickup_return.date_partner;
          booking.imponibile = pickup_return.simba_imponibile;
          booking.tot_fattura = booking.imponibile * 1.22;

          if (booking.loaner) {
            newPlannerReturn = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_return.point),
              pickup_return.date_partner,
              'PICKUP_AUTO_RETURN'
            );
            pickup_return.planners.push(newPlannerReturn.data.plannerDriver);

            pickup_return.date_customer =
              newPlannerReturn.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;
            pickup_return.date_driver =
              newPlannerReturn.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;

            pickup_return.driver_pickup = newPlannerReturn.data.driver.driverId;
          } else {
            newPlannerReturn = await addPlannerNew(
              //booking.headquarter,
              headquarter,
              toRaw(pickup_return.point),
              pickup_return.date_partner,
              'PICKUP_RETURN'
            );
            pickup_return.planners.push(newPlannerReturn.data.plannerDriver);
            pickup_return.planners.push(newPlannerReturn.data.plannerJolly);

            pickup_return.date_customer =
              newPlannerReturn.data.driver.trips[0].type == 'date_c1_p1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;
            pickup_return.date_driver =
              newPlannerReturn.data.driver.trips[0].type == 'date_p1_c1'
                ? newPlannerReturn.data.driver.trips[0].dateStart
                : newPlannerReturn.data.driver.trips[1].dateStart;

            pickup_return.driver_pickup = newPlannerReturn.data.driver.driverId;
          }

          booking.pickup_bookings.push(pickup_return);
        }

        response = await prenotazioneAPI.addBooking(booking);

        await prenotazioneAPI.addBookingNotification({
          customer: booking.customer,
          booking: response.data,
          area: booking.headquarter.area,
          minute: minuteCar.value,
        });

        return response;
      } catch (error) {
        /*  ElMessage({
          message: 'Errore booking!',
          type: 'error',
        }); */
        console.log(error);
      }
    };

    const addPlannerNew = async (
      originPoint,
      destinationPoint,
      partnerTime,
      type
    ) => {
      try {
        const data = {
          originPoint: originPoint,
          destinationPoint: destinationPoint,
          partnerTime: partnerTime,
          type: type,
          area: booking.headquarter.area,
        };

        let res = await prenotazioneAPI.calcDuration(data);

        return res;
      } catch (error) {
        ElMessage({
          message: 'Errore response!',
          type: 'error',
        });
        console.log(error);
      }
    };

    let highlightDescription = ref(false);
    let highlightCost = ref(false);

    const checkTask = () => {
      for (let task of booking.tasks) {
        if (task.description == '') {
          ElMessage.error('Perfavore inserisci una descrizione dei lavori');
          highlightDescription.value = true;
          return false;
        }

        if (task.cost == null) {
          ElMessage.error('Perfavore inserisci un costo dei lavori');
          highlightCost.value = true;
          return false;
        }

        if (task.cost > 0 && task.cost.includes(',')) {
          highlightCost.value = true;
          ElMessage.error('Perfavore non usare virgole per il costo');
          return false;
        }

        const floatRegex = /^-?\d+(\.\d+)?$/;
        if (!floatRegex.test(task.cost)) {
          highlightCost.value = true;
          ElMessage.error('Perfavore inserisci un costo valido');
          return false;
        }
        highlightDescription.value = false;
        highlightCost.value = false;
      }
      return true;
    };

    const onSubmit = async () => {
      if (!checkTask()) {
        return;
      }
      addBookingNew(toRaw(booking)).then(async (response) => {
        if (response.status === 201) {
          if (selectedFile1 !== null) {
            await fileLoad(fd);
          } else {
            await prenotazioneAPI.mailCustomer(booking.customer.mail);
          }

          ElMessage({
            message: 'Booking aggiunto!',
            type: 'success',
          });
          console.log('Booking aggiunto!');
        } else {
          ElMessage({
            message: 'Errore creazione booking!',
            type: 'error',
          });
          console.log('errore inatteso');
        }

        router.push('/');
      });
    };

    const props1 = {
      expandTrigger: 'hover',
    };

    const isInputFocused = ref(false);

    const handleInputFocusGone = () => {
      isInputFocused.value = true;
    };
    const handleInputBlurGone = async () => {
      isInputFocused.value = false;

      if (
        pickup_gone.point.address == '' ||
        pickup_gone.point.street_number == '' ||
        pickup_gone.point.city == '' ||
        pickup_gone.point.cap == '' ||
        pickup_gone.point.province == ''
      ) {
        button1_disabled.value = true;
        selectedSlotGone.value = null;
      } else {
        loadSlotGone(pickup_gone.date_partner);

        let resposnePointGone = await loadPoints(pickup_gone.point);

        pickup_gone.point.label = resposnePointGone.label;
        pickup_gone.point.latitude = resposnePointGone.point.latitude;
        pickup_gone.point.longitude = resposnePointGone.point.longitude;

        store.originPointAddress = pickup_gone.point.address;
        store.originPointStreetNumber = pickup_gone.point.street_number;
        store.originPointCity = pickup_gone.point.cap;
        store.originPointCap = pickup_gone.point.city;
        store.originPointProvince = pickup_gone.point.province;
        button1_disabled.value = false;
        // }
        calcolaKmGone();
      }
    };

    const handleInputFocusReturn = () => {
      isInputFocused.value = true;
    };
    const handleInputBlurReturn = async () => {
      isInputFocused.value = false;
      if (
        pickup_return.point.address == '' ||
        pickup_return.point.street_number == '' ||
        pickup_return.point.city == '' ||
        pickup_return.point.cap == '' ||
        pickup_return.point.province == ''
      ) {
        button1_disabled.value = true;
        selectedSlotReturn.value = null;
      } else {
        loadSlotReturn(pickup_return.date_partner);

        let resposnePoinReturn = await loadPoints(pickup_return.point);

        pickup_return.point.label = resposnePoinReturn.label;
        pickup_return.point.latitude = resposnePoinReturn.point.latitude;
        pickup_return.point.longitude = resposnePoinReturn.point.longitude;

        store.destinationPointAddress = pickup_return.point.address;
        store.destinationPointStreetNumber = pickup_return.point.street_number;
        store.destinationPointCity = pickup_return.point.cap;
        store.destinationPointCap = pickup_return.point.city;
        store.destinationPointProvince = pickup_return.point.province;
        button1_disabled.value = false;
        calcolaKmReturn();
      }
    };

    const back = () => {
      active.value = 0;
    };

    const isInputFocusedDate = ref(false);

    const handleInputFocusDateGone = () => {
      isInputFocusedDate.value = true;
    };
    const handleInputBlurDateGone = () => {
      isInputFocusedDate.value = false;
    };

    const handleChangeCost = (value, task) => {
      task.value.cost = value;
    };

    const handleChangePay = (value, task) => {
      task.value.pay = value;
    };

    const handleChangeNote = (value) => {
      booking.value.note = value;
    };

    const disabledDate = (vDate) => {
      let expirydate = new Date();
      return vDate <= expirydate.setDate(expirydate.getDate()-1);
      //return vDate < expirydate.setDate(expirydate.getDate() + 3);
    };

    const disabledDateReturn = (vDate) => {
      let expirydate = new Date();
     // console.log("🚀 ~ disabledDateReturn ~ expirydate:", expirydate)
      if (is_gone.value && pickup_gone.date_partner) {
        const partnerDate = new Date(pickup_gone.date_partner);
        partnerDate.setHours(0, 0, 0, 0);
        
       // console.log("🚀 ~ disabledDateReturn ~ partnerDate:", partnerDate)

        return vDate <= partnerDate.setDate(expirydate.getDate()-1);
        //return vDate <= partnerDate;
      }

      return vDate <= expirydate.setDate(expirydate.getDate()-1);
      //return vDate < expirydate.setDate(expirydate.getDate() + 3);
    };

    /*  watch([() => pickup_gone.point], ([pointGone]) => {
             if (pointGone.address == "" || pointGone.street_number == "" || pointGone.city == "" || pointGone.cap == "" || pointGone.province == "") {
                 button1_disabled.value = true;
             } else {
                 button1_disabled.value = false;
             }

             calcolaKmGone();

         }, {
             deep: true,
             immediate: false,
         }); */

    /* watch([() => pickup_return.point], ([pointReturn]) => {
            if (pointReturn.address == "" || pointReturn.street_number == "" || pointReturn.city == "" || pointReturn.cap == "" || pointReturn.province == "") {
                button1_disabled.value = true;
            } else {
                button1_disabled.value = false;
            }
            calcolaKmReturn();

        }, {
            deep: true
        }); */

    return {
      loadingSlotsGone,
      loadingSlotsReturn,
      back,
      handleInputFocusGone,
      handleInputBlurGone,
      handleInputFocusReturn,
      handleInputBlurReturn,
      fileLoad,
      isAvantiButtonDisabled,
      headquarterSelected,
      customerSelected,
      carSelected,
      handleChangeDateGone,
      handleChangeDateReturn,
      pickup_gone,
      pickup_return,

      calcolaKmGone,
      calcolaKmReturn,
      responseKmGone,
      responseCostGone,
      responseKmReturn,
      responseCostReturn,

      changeLoaner,
      deleteGone,
      is_gone,
      deleteReturn,
      is_return,
      selectedSlotGone,
      selectedSlotReturn,
      timeSlotsGone,
      timeSlotsReturn,
      handleSlotChangeGone,
      handleSlotChangeReturn,

      //changeValue_simbaPay,
      show_minute,
      onFileSelected,
      active,
      next,
      tasks,
      loadCars,
      addBookingNew,
      customers,
      customersId,
      booking,

      carsId,
      headquartersId,
      headquarters,
      onSubmit,
      props1,
      handleChangeCar,

      handleChangeCustomer,
      handleChangeHeadquarter,
      aggiungiTask,
      rimuoviTask,
      Delete,
      button1_disabled,
      button2_disabled,
      residual_minuteVal,

      handleInputFocusDateGone,
      handleInputBlurDateGone,

      formRulePickup,
      formRuleBooking,

      highlightCustomer,
      highlightHeadquarter,
      highlightCar,
      highlightDateGone,
      highlightDateReturn,
      highlightSlotGone,
      highlightSlotReturn,
      SlotGone,
      SlotReturn,

      highlightDescription,
      highlightCost,
      SelectComponent,
      DescriptionComponent,
      loadPoints,
      loadingPoint,

      handleChangeCost,
      handleChangePay,
      handleChangeNote,

      customerExist,
      checkCustomerExist,

      disabledDate,
      disabledDateReturn,
    };
  },
};
</script>

<style>
.card-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.box-card {
  width: 100%;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.select-component:last-child {
  margin-right: 0;
}

.select-component {
  flex: 2;
  margin-right: 20px;
}

.form-section {
  display: flex;
  flex: 1;
}

.form-label {
  flex: 1;
  display: flex;
  font-weight: bold;
}

.form-control {
  flex: 2;
  display: flex;
  justify-content: flex-start;
}

.switch-container {
  margin-top: 20px;
  text-align: left;
}

.point-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.info-container {
  display: flex;
  justify-content: center;
  gap: 22px;
  margin-top: 20px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.container > div {
  margin-right: 10px;
}

.container-data {
  display: flex;
  align-items: center;
  padding-top: 2%;
}

.container-data > div {
  margin-right: 10px;
}

.container-point {
  display: flex;
  padding-top: 2%;
  flex-wrap: wrap;
}

.container-point > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.container-point > *:last-child {
  margin-right: 0;
}

.task-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 14%;
}

.task-row > div {
  margin-right: 10px;
}

.custom-switch .el-switch__label {
  font-weight: bold;
}

.container-customer {
  display: flex;
  padding-top: 2%;
  flex-wrap: wrap;
  justify-content: center;
}

.container-customer > * {
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
